import React from 'react';
//import cx from 'clsx';
//import { makeStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Share from '@material-ui/icons/Share';
import Typography from '@material-ui/core/Typography';
import { Link} from "gatsby"
import Img from "gatsby-image"
import { darkerTeal } from './statics';
import ChevronRightRounded from '@material-ui/icons/ChevronRightRounded';
//import TextInfoContent from '@mui-treasury/components/content/textInfo';
import "../styles/global.css"
 
 
const textInfoCss = ({ palette  }) => ({
  firstLine: {
    textTransform: 'uppercase',
    //color: palette.text.secondary,
    color: 'gray',
    letterSpacing: '1px',
    fontSize: 12,
    //marginBottom: '0.875em',
    display: 'inline-block',
  },
  header: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: '0.4em',
    color: 'black',
  },
  body: {
    fontSize: 16,
    color: 'rgba(0,0,0,0.72)',
  },
});


 
const TextInfoContent = ({
  //useStyles,
  firstLine,
  header,
  body,
  firstLineProps,
  headerProps,
  bodyProps,
  //...props
}) => {
  //const css = useStyles(props);
 const s1 = textInfoCss({});
 console.log('s1 check: ', s1.firstLine);

  return (
    <>
      {firstLine && (
        <Typography
          component={'span'}
          {...firstLineProps}
           style={s1.firstLine}
        >
          {firstLine}
        </Typography>
      )}
      {header && (
      <Typography
        component={'h4'}
        {...headerProps}
        style={s1.header}
       >
        {header}
      </Typography>
      )}

      {body && (
      <Typography {...bodyProps} 
      style={s1.body}
      >
        {body}
      </Typography>
      )}
    </>
  );
}; 



const NewsCard = ({linkPath = '/', linkText = 'Find Out More', firstLine = 'First', 
                    header = 'Header', body = 'Body', 
                    image = 'https://images.unsplash.com/photo-1468774871041-fc64dd5522f3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2689&q=80',
                    props 
  }) => {
console.log('image val: ', image);
console.log('NewsCard props: ', props);

    return (
    <Card
       className={'bouncyShadow'} 
    style={{
      //inline styling should supercede css from classNames
      //Card-creator recommended style, do not think this is what we want?:
      //maxWidth: 304,
      margin: 'auto',
      //boxShadow: 'none',
      //borderRadius: 0,
    }}
    elevation={1}
    >
 


 <Link to={linkPath}>

{/*Feels awkward, but wraping CardMedia WITH Image, seems to put image 'in' CardMedia?*/} 
<Img
fluid={image.childImageSharp.fluid}
style={{
maxHeight:200,
}}
imgStyle={{ objectFit: "contain" }} //need or will not size correctly inside nested components I think
>
{/*In theory we could even remove this CardMedia component*/}
<CardMedia
       style={{
     
        width: '100%',
       //height: 0,
       //maxHeight:150,
       paddingBottom: '56.25%',
       backgroundColor: 'rgba(0, 0, 0, 0.08)',
       //image={'https://images.unsplash.com/photo-1468774871041-fc64dd5522f3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2689&q=80'} />
 }}
 />
       

</Img>

      <CardContent 
      style={{padding: 24,}}
      >

       {TextInfoContent({firstLine, header, body})}

        {/*Use below for debugging
        <TextInfoContent
          firstLine={'March 20, 2019'}
          header={'What happened in Thailand?'}
          body={
            'Kayaks crowd Three Sisters Springs, where people and manatees maintain controversial coexistence.'
          }
        />
        */}
       
        </CardContent>


        <Box 
        px={2} pb={2} mt={-1}
        style={{display:'block', //we 'flex' by default, override in this specific case
                                 //allows nested buttons to appear horizontally next to each other
         //backgroundColor:'pink',
        }} 
      >
        {/*While a share would look nice, not convinced we need
        <IconButton>
          <Share /> 
        </IconButton>
        */}

        
        <Button color={'primary'} fullWidth 
         style={{ 
          //marginTop: 24,
          //width: 'auto', //take up rest of avail. horizontally, when share button above is also present
          textTransform: 'initial',
          color: darkerTeal,
          }}
          onClick={()=><Link to="/"/>}
        >
           
          {linkText}
          <ChevronRightRounded />

        </Button>
        

       </Box>

       </Link>
    </Card>

  );
};


export default NewsCard;