import React from "react"
import { Link, graphql} from "gatsby"
import MyLayout from "../components/layout"
import SEO from "../components/seo"
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import { Col, Row } from "antd"

import "../styles/global.css"

import NewsCard from '../components/newsCard';
const SecondPage = ({data, props}) => {
 console.log('DEBUG blog.js data: ', JSON.stringify( data ));

  let nodes = data.allMarkdownRemark.edges; //Default Props
  //console.log('DEBUG blog.js nodes: ', JSON.stringify( nodes )); 

  //console.log('DEBUG blogs.js: ', JSON.stringify( frontmatter ));
  let img =  'https://images.unsplash.com/photo-1468774871041-fc64dd5522f3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2689&q=80';
  let tileData=[{key: 0, img},{key: 1, img}];

  //can probably remove below, not needed 

 return (
  <MyLayout>
    <SEO title="Page two" />
  
  
    <Container 
      //maxWidth="sm"
      //style={{backgroundColor:'blue'}}
      //className={"bouncyShadow"}
    >
      <div 
      style={{ alignItems:'center'}}>
      
        <h2 >Articles</h2>
      </div>

    <Box component="span" 
    style={{backgroundColor:'blue', }}
 
    >
      {/* Removed because tiles look better on slight gray background
      <Paper elevation={3} 
      //a container that simulates paper (shadow underneath)
        //  style={{
        //    //backgroundColor:'red',
        //   paddingTop:40,
        //   paddingLeft:30,
        //   marginLeft:10,
        //   marginRight:10,
        //   paddingRight:30,
        //   paddingBottom:20,
        // }}
      >  */}

        <Row
          type={"flex"}
          justify={"start"}
          align={"space-around"}
          style={{
            //backgroundColor:'red',
            //paddingLeft: "5%",
           // paddingRight: "5%", //on very wide views, bring the content together
          }}
        >


          {nodes.map( node => {
                //console.log('whats in my node: ', node);
                //console.log('whats in my node.node.frontmatter: ', node.node);

               //not displayed on newsCard but could be: readMins
              let { date, path, title, bannerImage, shortDescription, linkText } = node.node.frontmatter;
              return (
          
                <Col
                xs={24}
                sm={12}
                md={9}  //making 8, makes the components 'nearly-touch' before collapsing to 2 columns (above)
                //8 => 3 columns per row (since 24 is max span)
                lg={7}  
                xl={7} //collapse underneat if not enough width
                style={{
                   //backgroundColor:'pink',
                }}
              >
             {/*warning: removing div, but will negatively impact nested component style appearance*/}
                <div style={{paddingBottom:40,}}> 

                  <NewsCard
                  props={node.node}
                  firstLine={date}
                  linkPath={path}
                  header={title}
                  image={bannerImage}
                  body={shortDescription} //OR take first few words of post?
                  linkText={linkText}
                  /> 

                </div>

               </Col>


 




    )
    })}

</Row>
 
 {/*</Paper> */}

 </Box>

 </Container>
  </MyLayout>
)
}


//gatsby looks for an exported graphql string from each file, rather than a specific exported varialbe
//you can only have one graphql string query per file
//gatsby passes query result into the component as a 'data' property
//debug: localhost:8000/___graphql

//The below query is identical to query found in blogTemplate.js (and should always be.. )
//todo, COMBINE into 1 
//very similar to gatsby-node.js
export const pageQuery = graphql`    {
  allMarkdownRemark(
    sort: { order: DESC, fields: [frontmatter___date] }
    limit: 1000
  ) {
    edges {
      node {
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          path
          title
          readMins
          shortDescription
          linkText
          bannerImage {
            childImageSharp {
              fluid(maxWidth: 1500, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
}
`

export default SecondPage
